<app-subheading title="My DARs"></app-subheading>
<div class="page-container">

  <mat-form-field class="half-width">
    <mat-label>Filter By Status</mat-label>
    <mat-select (selectionChange)="updateQuery()" #selectedDarStatus>
      <mat-option value="">All [Active]</mat-option>
      <mat-option *ngFor="let item of darStatuses" [value]="item.key">
        {{ item.value | titlecase }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="half-width">
    <mat-label>Filter By Role</mat-label>
    <mat-select (selectionChange)="updateQuery()" #selectedRole>
      <mat-option value="">All</mat-option>
      <mat-option value="owner">Owner</mat-option>
      <mat-option value="stakeholder">Stakeholder</mat-option>
      <mat-option value="evaluator">Evaluator</mat-option>
      <mat-option value="voter">Voter</mat-option>
      <mat-option value="reader">Reader</mat-option>
    </mat-select>
  </mat-form-field>

  <table mat-table class="mat-elevation-z8 tableSizeMaximizer" [dataSource]="dars$ | async" matSortDisableClear>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Title</th>
      <td mat-cell *matCellDef="let element" style="min-width: 100px">
        <a routerLink="/darview/{{ element.id }}" matTooltip="View DAR document">{{ element.title }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="darStatus">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let dar" style="min-width: 60px">
        {{ DarStatus[dar.darStatus] | titlecase }}
      </td>
    </ng-container>

    <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef>Roles</th>
      <td mat-cell *matCellDef="let element" style="min-width: 80px">
        <div class="userRole" *ngIf="element.darUserIndexes.isOwner.includes(auth.currentUser.uid)">
          Owner
        </div>
        <div class="userRole" *ngIf="
            element.darUserIndexes.isStakeholder.includes(auth.currentUser.uid)
          ">
          Stakeholder
        </div>
        <div class="userRole" *ngIf="
            element.darUserIndexes.isEvaluator.includes(auth.currentUser.uid)
          ">
          Evaluator
        </div>
        <div class="userRole" *ngIf="element.darUserIndexes.isReader.includes(auth.currentUser.uid)">
          Reader
        </div>
        <div class="userRole" *ngIf="element.darUserIndexes.isVoter.includes(auth.currentUser.uid)">
          Voter
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="
            element.darUserIndexes.isEvaluator.includes(auth.currentUser.uid) &&
            element.darStatus == DarStatus.evaluate
          ">
          <button mat-button class="actionButton" matTooltip="Perform evaluation of the solutions against criteria"
            routerLink="/darevaluations/{{ element.id }}">
            <i class="material-icons-outlined">
              ballot
            </i>
          </button>
        </span>
        <span *ngIf="element.darUserIndexes.isVoter.includes(auth.currentUser.uid) &&
          element.darStatus == DarStatus.vote
          ">
          <button mat-button class="actionButton" matTooltip="Vote for the solution"
            routerLink="/darvote/{{ element.id }}">
            <i class="material-icons-outlined">
              done
            </i>
          </button>
        </span>
        <span *ngIf="
            element.darUserIndexes.isStakeholder.includes(auth.currentUser.uid) &&
            element.darStatus == DarStatus.confirm
          ">
          <button mat-button class="actionButton" matTooltip="Stakeholder confirms the solution decision"
            routerLink="/darconfirm/{{ element.id }}">
            <i class="material-icons-outlined">
              done_all
            </i>
          </button>
        </span>
        <!--  enable to test isRole route guard
        <span>
          <button mat-button class="actionButton" matTooltip="Test" routerLink="/darconfirm/{{ element.id }}">
            <i class="material-icons-outlined" style="color: blueviolet">
              done_all
            </i>
          </button>
        </span> -->
        <span *ngIf="element.darUserIndexes.isOwner.includes(auth.currentUser.uid)">
          <button mat-button class="actionButton" matTooltip="Edit the DAR" routerLink="/darfolder/{{ element.id }}">
            <i class="material-icons-outlined">
              edit
            </i>
          </button>
        </span>


      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
