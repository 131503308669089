<!-- Image carousel showing applications main functions -->

<ngb-carousel>
  <ng-template ngbSlide class="carousel-item carousel-image-1 border-10">
    <picture>
      <source
        media="(min-width: 650px)"
        srcset="../../assets/images/home-1-1200x500.jpg"
      />
      <source
        media="(min-width: 465px)"
        srcset="../../assets/images/home-1-400x300.jpg"
      />
      <img
        src="../../assets/images/home-1-400x300.jpg"
        alt="Checklist"
        width="100%"
      />
    </picture>
    <div class="carousel-caption ">
      <h4><i class="far fa-list-alt"></i> Decisions</h4>
      <p>Follow standard decision practice.</p>
    </div>
  </ng-template>
  <ng-template ngbSlide class="carousel-item carousel-image-2 border-10">
    <picture>
      <source
        media="(min-width: 650px)"
        srcset="../../assets/images/home-2-1200x500.jpg"
      />
      <source
        media="(min-width: 465px)"
        srcset="../../assets/images/home-2-400x300.jpg"
      />
      <img
        src="../../assets/images/home-2-400x300.jpg"
        alt="Checklist"
        width="100%"
      />
    </picture>
    <div class="carousel-caption ">
      <h4><i class="far fa-list-alt"></i> Collaboration</h4>
      <p>Realtime collaboration on Decisions.</p>
    </div>
  </ng-template>
</ngb-carousel>

<!-- Cards with more information about application functions (align with carousel info) -->

<div class="card-container">
  <mat-card class="home-card">
    <mat-card-header class="card-header">
      <div mat-card-avatar class="card-1-image"></div>
      <mat-card-title>Decisions</mat-card-title>
      <mat-card-subtitle>A Standard Decision Process</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <p>
        Use a standard decision making process (Support CMMI DAR "Decision Analysis & Resolution")
      </p>
      <li>Identify Participants</li>
      <li>Identify options</li>
      <li>Agree Criteria</li>
      <li>Score and Rank Options</li>
      <li>Agree & Document Decision</li>
    </mat-card-content>
  </mat-card>

  <mat-card class="home-card">
    <mat-card-header class="card-header">
      <div mat-card-avatar class="card-2-image"></div>
      <mat-card-title>Collaboration</mat-card-title>
      <mat-card-subtitle>Real Time Collaboration</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <p>
        Perform collaborative decision process in real time
      </p>
      <li>Real time interactions</li>
      <li>Strong audit and authorization controls</li>
      <li>Collaboration across geography and time zones</li>
    </mat-card-content>
  </mat-card>
</div>
