<app-subheading title="Administration"></app-subheading>
<div class="page-container">
  Select the administration activity from the list below.

  <mat-card class="adminMenuCard" routerLink="/users">
    <mat-card-title>
      <i class="material-icons-outlined">
        how_to_reg
      </i> Manage Users</mat-card-title>
    <mat-card-content>Review Users, assign roles to users, activate/deactivate
      users</mat-card-content>
  </mat-card>
  <mat-card class="adminMenuCard" routerLink="/teams">
    <mat-card-title>
      <i class="material-icons-outlined">
        people
      </i> Manage Teams</mat-card-title>
    <mat-card-content>Manage team definitions
    </mat-card-content>
  </mat-card>
  <mat-card class="adminMenuCard" routerLink="/adminDars">
    <mat-card-title> <i class="material-icons-outlined">
        transform
      </i> Manage DARs</mat-card-title>
    <mat-card-content>Perform administration of DARs
    </mat-card-content>
  </mat-card>
  <mat-card class="adminMenuCard" routerLink="/darimport">
    <mat-card-title> <i class="material-icons-outlined">
        cloud_upload
      </i> Import DAR</mat-card-title>
    <mat-card-content>Import a DAR Json file as a new DAR.
    </mat-card-content>
  </mat-card>
</div>
