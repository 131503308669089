<table class="heatTable">
  <tr>
    <th></th>
    <th class="rotate" *ngFor="let darSolution of darsolutions" [matTooltip]="'Solution: ' + darSolution.name">
      <div>
        <span>{{
          darSolution.name.length > 20
            ? darSolution.name.slice(0, 20) + "..."
            : darSolution.name
        }}</span>
      </div>
    </th>
  </tr>
  <ng-container *ngFor="let darCriteria of darcriterias">
    <tr>
      <td class="evaluationSolution" [matTooltip]="'Criteria:' + darCriteria.name">{{
        darCriteria.name.length > 25
          ? darCriteria.name.slice(0, 25) + "..."
          : darCriteria.name
      }}</td>
      <ng-container *ngFor="let darSolution of darsolutions">
        <ng-container *ngIf="displayEvaluationCell(darSolution, darCriteria) as cellContent">
          <td class="evaluationCell" [matTooltip]="cellContent.cellTooltip"
            [style.background-color]="cellContent.cellBGColor">
            {{ cellContent.cellText }}
          </td>
        </ng-container>
      </ng-container>

    </tr>
  </ng-container>
  <tr>
    <td class="totalsHeading">Totals</td>
    <ng-container *ngFor="let darSolution of darsolutions">
      <td class="totalsCell" *ngIf="darSolution">{{displaySolutionScore(darSolution)}}</td>
    </ng-container>
  </tr>
</table>
<mat-expansion-panel class="evaluationDetails">
  <mat-expansion-panel-header>
    <div class="detailsHeader">Details</div>
  </mat-expansion-panel-header>

  <ng-template matExpansionPanelContent>
    <app-darviewevaluationdetails [dar]="dar"></app-darviewevaluationdetails>
  </ng-template>
</mat-expansion-panel>
