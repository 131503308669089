<app-subheading title="Help"></app-subheading>
<div class="page-container">
  Manual
  <div class="help-content">
    The ourDars manual walks though the ourDars workflow, technical details and
    more information about CMMI dar practices.<br />
    <a href="../../assets/ourDars Manual.pdf" target="_blank">ourDars Manual</a>
  </div>
  Github
  <div class="help-content">
    Source code is free to use and is available at
    <a href="https://github.com/somervda/ourDars" target="_blank"
      >https://github.com/somervda/ourDars</a
    >
  </div>

  Video Tutorials
  <div class="help-content">
    <youtube collection="ourDars" [isAsc]="true"></youtube>
  </div>
</div>
