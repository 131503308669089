<app-subheading title="Users"></app-subheading>
<div class="page-container">


  <div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner></mat-spinner>
  </div>

  <table mat-table class="mat-elevation-z8 tableSizeMaximizer" [dataSource]="dataSource" matSort matSortActive="email"
    matSortDirection="asc" matSortDisableClear>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>eMail</th>
      <td mat-cell *matCellDef="let user">
        <a routerLink="/user/{{ user.uid }}">{{ user.email }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="displayName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let user">{{ user.displayName }}</td>
    </ng-container>

    <ng-container matColumnDef="team">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Team</th>
      <td mat-cell *matCellDef="let user">{{ user?.team?.name }}</td>
    </ng-container>

    <ng-container matColumnDef="isActivated">
      <th mat-header-cell *matHeaderCellDef>Activated</th>
      <td mat-cell *matCellDef="let user">
        {{ user.isActivated ? "Yes" : "No" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="isAdmin">
      <th mat-header-cell *matHeaderCellDef>Admin</th>
      <td mat-cell *matCellDef="let user">{{ user.isAdmin ? "Yes" : "No" }}</td>
    </ng-container>

    <ng-container matColumnDef="isDarCreator">
      <th mat-header-cell *matHeaderCellDef>Creator</th>
      <td mat-cell *matCellDef="let user">{{ user.isDarCreator ? "Yes" : "No" }}</td>
    </ng-container>



    <ng-container matColumnDef="dateCreated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
      <td mat-cell *matCellDef="let user">
        {{
          user.dateCreated
            ? (user.dateCreated.toDate() | date: "mediumDate")
            : ""
        }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
