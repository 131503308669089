<app-subheading title="DAR Administration"></app-subheading>
<div class="page-container">
  <mat-form-field class="half-width">
    <mat-label>Filter By Status</mat-label>
    <mat-select (selectionChange)="onFilterChange()" #selectedDarStatus>
      <mat-option value="">All</mat-option>
      <mat-option *ngFor="let item of darStatuses" [value]="item.key">
        {{ item.value | titlecase }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="half-width">
    <mat-label
      >Filter by title (Enter the initial characters to be matched)</mat-label
    >
    <input matInput (keyup)="onFilterChange()" #titleFilter />
  </mat-form-field>

  <table
    mat-table
    class="mat-elevation-z8 tableSizeMaximizer"
    [dataSource]="dars$ | async"
    matSortDisableClear
  >
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Title (Click to Edit)</th>
      <td mat-cell *matCellDef="let dar" style="min-width: 100px">
        <a
          routerLink="/darfolder/{{ dar.id }}"
          matTooltip="Edit DAR document"
          >{{ dar.title }}</a
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="darStatus">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let dar" style="min-width: 60px">
        {{ DarStatus[dar.darStatus] | titlecase }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let dar">
        {{
          dar.description
            ? dar.description.length < 100
              ? dar.description
              : dar.description.substring(0, 99) + "..."
            : ""
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="export">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell class="exportDar" *matCellDef="let dar">
        <mat-icon
          matTooltip="Export DAR document"
          [routerLink]="['/darexport/', dar.id]"
          >cloud_download</mat-icon
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell class="deleteRow" *matCellDef="let dar">
        <mat-icon
          matTooltip="Delete DAR document"
          [routerLink]="['/darfolder/delete/', dar.id]"
          >clear</mat-icon
        >
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
