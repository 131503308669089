<div class="page-container">
  <div>Description: {{ darsolution.description }}</div>
  <div *ngIf="darsolution.evaluationNotes">
    Evaluation Notes: {{ darsolution.evaluationNotes }}
  </div>

  <div *ngFor="let darcriteria of darcriteria$ | async">
    <app-darevaluationcriteria [darcriteria]="darcriteria" [darsolution]="darsolution" [dar]="dar">
    </app-darevaluationcriteria>
  </div>
</div>
