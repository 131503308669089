<!-- <h4>Solution Details</h4> -->
<mat-card class="container-card" *ngIf="_dsid || _crudAction == Crud.Create">
  <form class="standard-form" [formGroup]="form">
    <mat-form-field class="full-width">
      <input matInput formControlName="name" (change)="onFieldUpdate('name')" placeholder="Solution Name" required />
      <mat-error *ngIf="form.controls['name'].hasError('required')">
        Team name is required.
      </mat-error>
      <mat-error *ngIf="form.controls['name'].hasError('minlength')">
        Team name must be at least 10 characters long.
      </mat-error>
      <mat-error *ngIf="form.controls['name'].hasError('maxlength')">
        Team name must be no more than 40 characters long.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
      <textarea matInput formControlName="description" (change)="onFieldUpdate('description')" matAutosizeMinRows="4"
        matAutosizeMaxRows="10" matTextareaAutosize placeholder="Solution Description" required></textarea>
      <mat-error *ngIf="form.controls['description'].hasError('required')">
        Description is required.
      </mat-error>
      <mat-error *ngIf="form.controls['description'].hasError('minlength')">
        Description must be at least 20 characters long.
      </mat-error>
      <mat-error *ngIf="form.controls['description'].hasError('maxlength')">
        Description must be no more than 1000 characters long.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
      <textarea matInput formControlName="evaluationNotes" (change)="onFieldUpdate('evaluationNotes')"
        matAutosizeMinRows="4" matAutosizeMaxRows="10" matTextareaAutosize placeholder="Evaluation Notes"></textarea>
      <mat-error *ngIf="form.controls['description'].hasError('maxlength')">
        Evaluation notes must be no more than 1000 characters long.
      </mat-error>
      <mat-hint>Notes to help with evaluating the solution.</mat-hint>
    </mat-form-field>

  </form>

  <button mat-raised-button *ngIf="_crudAction == Crud.Create" color="primary" [disabled]="!form.valid"
    (click)="onCreate()" id="btnCreate">
    <mat-icon>add</mat-icon>
    Create
  </button>

  <button mat-raised-button color="primary" *ngIf="_crudAction == Crud.Delete" (click)="onDelete()" id="btnDelete">
    <mat-icon>clear</mat-icon>
    Delete
  </button>


</mat-card>
