<app-subheading title="Teams"></app-subheading>
<div class="page-container">

  <button mat-raised-button routerLink="/team/create">
    <i class="material-icons-outlined">
      people
    </i>&nbsp;
    <span>Create New Team</span>
  </button>

  <div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner></mat-spinner>
  </div>

  <br>

  <table mat-table class="mat-elevation-z8 tableSizeMaximizer" [dataSource]="dataSource" matSort matSortActive="name"
    matSortDirection="asc" matSortDisableClear>
    <ng-container matColumnDef="name" sticky>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let team">
        <a routerLink="/team/{{ team.id }}">{{ team.name }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let team">
        {{
          team.description.length < 100
            ? team.description
            : team.description.substring(0, 99) + "..."
        }}

      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell class="deleteRow" *matCellDef="let team">
        <mat-icon matTooltip="Delete Team" [routerLink]="['/team/delete/', team.id]">clear</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
