export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAiFuqfwk-DrcmSucwXYlIcpvldHwHxL2g",
    authDomain: "ourdars-7b9e2.firebaseapp.com",
    databaseURL: "https://ourdars-7b9e2.firebaseio.com",
    projectId: "ourdars-7b9e2",
    storageBucket: "ourdars-7b9e2.appspot.com",
    messagingSenderId: "961010974763",
    appId: "1:961010974763:web:470ec34492770f01"
  }
};
