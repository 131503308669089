<app-subheading title="Evaluate DAR Solutions"></app-subheading>
<div class="page-container">
  <mat-accordion multi="false">
    <div *ngFor="let darsolution of darsolutions$ | async">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <div class="solutionHeader">Solution: {{darsolution.name}}</div>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <app-darevaluation [darsolution]="darsolution" [dar]="dar"></app-darevaluation>
        </ng-template>
      </mat-expansion-panel>

    </div>
  </mat-accordion>
</div>
