<mat-card>
  <mat-card-header class="card-header">
    <mat-card-title>{{ darcriteria.name }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div>{{ darcriteria.description }}</div>
    <div class="weighting">{{ CriteriaWeighting[darcriteria.weighting] }}</div>

    <mat-form-field class="half-width" style="margin-top: 8px;">
      <mat-label>Criteria Evaluation Score</mat-label>
      <mat-select [value]="darevaluation?.evaluationScore" (selectionChange)="onUpdate()" #evaluationScore>
        <mat-option [value]=""> </mat-option>
        <mat-option *ngFor="let item of evaluationScoreItems" [value]="item.key">
          {{ item?.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width">
      <textarea matInput (change)="onUpdate()" placeholder="Notes"
        [value]="darevaluation?.notes ? darevaluation.notes : ''" #notes></textarea>
    </mat-form-field>
  </mat-card-content>
</mat-card>
