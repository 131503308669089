<div class="page-container">
  <div class="table-container">
    <div *ngIf="(darcriteria$ | async)?.length ==0">No criteria defined. Press "New Criteria" to create criteria for
      this decision.</div>
    <table mat-table [dataSource]="darcriteria$ | async" class="mat-elevation-z8">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef style="padding-left: 20px">Name (Click to edit)</th>
        <td mat-cell *matCellDef="let element" style="width: 100%">
          <button mat-button (click)="selectCriteria(element.id)" style="width: 100%;text-align: left;">
            {{element.name}} </button> </td>
      </ng-container>

      <ng-container matColumnDef="weighting">
        <th mat-header-cell *matHeaderCellDef>Weighting</th>
        <td mat-cell *matCellDef="let element">
          {{CriteriaWeighting[element.weighting]}}
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon class="deleteIcon" matTooltip="Delete Criteria" (click)="selectDeleteCriteria(element.id)">clear
          </mat-icon>
        </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>
  <button style="margin-top: 5px;" mat-raised-button (click)="onCreateNew()">
    <mat-icon>add</mat-icon>New Criteria
  </button>


  <app-darcriteria [did]="dar.id" [dcid]="dcid" [crudAction]="crudAction" [dummyValue]="forCD"></app-darcriteria>
</div>
