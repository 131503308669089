<mat-sidenav-container>
  <!-- add this to the mat-sidebar tag to have menu auto close (click)="sidenav.toggle()" -->
  <mat-sidenav #sidenav (click)="sidenav.toggle()" class="no-print">
    <mat-nav-list>
      <a mat-list-item routerLink="/">
        <i class="material-icons-outlined">
          home
        </i>
        <span id="mainMenuHome">Home</span>
      </a>

      <!-- Logged in and activated options -->
      <span
        *ngIf="(auth.loggedIn$ | async) && (auth.user$ | async)?.isActivated"
      >
        <!-- User functions  -->
        <a mat-list-item routerLink="mydars">
          <i class="material-icons-outlined">
            transform
          </i>
          <span id="mainMenuMyDars">My DARs</span>
        </a>

        <a
          mat-list-item
          routerLink="/darfolder/create"
          *ngIf="(auth.user$ | async)?.isDarCreator"
        >
          <i class="material-icons-outlined">
            add
          </i>
          <span id="mainMenuCreateDar">Create DAR</span>
        </a>

        <a
          mat-list-item
          [routerLink]="['user/', (auth.user$ | async)?.uid || '']"
        >
          <i class="material-icons-outlined">
            person
          </i>
          <span id="mainMenuMyProfile">My Profile</span>
        </a>

        <!-- Administrator Functions -->
        <a
          mat-list-item
          *ngIf="(auth.user$ | async)?.isAdmin"
          mat-list-item
          routerLink="administration"
        >
          <i class="material-icons-outlined">
            security
          </i>
          <span id="mainMenuAdministration">Administration</span>
        </a>
      </span>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink="about">
        <i class="material-icons-outlined"> question_answer </i>&nbsp;
        <span id="mainMenuAbout">About</span>
      </a>
      <a mat-list-item routerLink="help">
        <i class="material-icons-outlined"> help_outline </i>&nbsp;
        <span id="mainMenuHelp">Help</span>
      </a>
      <!-- Logged in options -->
      <span *ngIf="auth.loggedIn$ | async">
        <mat-divider></mat-divider>
        <a
          mat-list-item
          routerLink="logout"
          (click)="logout()"
          *ngIf="auth.loggedIn$ | async"
        >
          <i class="material-icons-outlined">
            exit_to_app
          </i>
          <span id="mainMenuLogout">Logout</span>
        </a>
      </span>

      <!-- Not logged in -->
      <span *ngIf="!(auth.loggedIn$ | async)">
        <mat-divider></mat-divider>
        <a mat-list-item routerLink="login">
          <i class="material-icons-outlined">
            account_circle
          </i>
          <span id="mainMenuLogin">Login</span>
        </a>
      </span>
    </mat-nav-list>
  </mat-sidenav>

  <mat-toolbar color="primary" class="no-print">
    <button
      mat-icon-button
      class="noOutline"
      (click)="sidenav.open('mouse')"
      id="mainMenu"
    >
      <i class="material-icons-outlined">
        menu
      </i>
    </button>

    <div class="filler"></div>
    <div *ngIf="!isConnected" style="margin-right: 20px">
      <mat-chip-list>
        <mat-chip
          class="offline-indicator"
          title="Application is offline and will have more limited functionality."
          >Offline
        </mat-chip>
      </mat-chip-list>
    </div>
    ourDARs &nbsp;
    <ng-container *ngIf="auth.user$ | async as user">
      <img
        *ngIf="user.isActivated"
        class="user-avatar"
        [src]="user.photoURL"
        [matTooltip]="user.displayName"
      />
      <img
        *ngIf="!user.isActivated"
        class="user-avatar"
        src="../../assets/images/Block_user_pic.png"
        matTooltip="You are not activated, contact an administrator to have your account activated to use this system."
      />
    </ng-container>
  </mat-toolbar>
  <router-outlet></router-outlet>
</mat-sidenav-container>
