<app-subheading [title]="dar.title"></app-subheading>
<div class="page-container">
  <table style="width : 100%">
    <tr>
      <td class="rowprompt">
        Description
      </td>
      <td class="rowcontent">
        {{ dar.description }}
      </td>
    </tr>

    <tr>
      <td class="rowprompt">
        DAR ID
      </td>
      <td class="rowcontent">
        {{ dar.id }}
      </td>
    </tr>

    <tr>
      <td class="rowprompt">
        Title
      </td>
      <td class="rowcontent">
        {{ dar.title }}
      </td>
    </tr>

    <tr>
      <td class="rowprompt" matTooltip="Chosen solution for this DAR">
        Solution
      </td>
      <td class="rowcontent">
        <u>
          {{ (darSolution$ | async)?.name }}
        </u>
      </td>
    </tr>

    <tr>
      <td class="rowprompt">
        Status
      </td>
      <td class="rowcontent">
        {{ DarStatus[dar.darStatus] | titlecase }}
        <ng-container
          *ngIf="darNextStatus.comment && darNextStatus.comment != ''"
        >
          <div
            class="full-width page-hint"
            matTooltip="Information about the next available status for this DAR and suggested actions"
          >
            <i class="material-icons-outlined" style="color: #d5aa46">
              emoji_objects
            </i>

            {{ darNextStatus.comment }} <i>{{ darNextStatus.explanation }}</i>
          </div>
        </ng-container>
      </td>
    </tr>

    <tr>
      <td class="rowprompt">
        Methodology
      </td>
      <td class="rowcontent">
        {{ DarMethod[dar.darMethod] }}
      </td>
    </tr>

    <tr>
      <td class="rowprompt">
        Created
      </td>
      <td class="rowcontent">
        {{
          dar.dateCreated &&
          dar.dateCreated.seconds &&
          dar.dateCreated.nanoseconds
            ? dar.dateCreated.toDate().toDateString()
            : ""
        }}
      </td>
    </tr>

    <tr>
      <td class="rowprompt">
        Target Date
      </td>
      <td class="rowcontent">
        {{ dar.dateTargeted ? dar.dateTargeted.toDate().toDateString() : "" }}
      </td>
    </tr>

    <tr>
      <td class="rowprompt">
        Closed
      </td>
      <td class="rowcontent">
        {{ dar.dateClosed ? dar.dateClosed.toDate().toDateString() : "" }}
      </td>
    </tr>

    <tr>
      <td class="rowprompt">
        Team
      </td>
      <td class="rowcontent">
        {{ (team$ | async)?.name }}
      </td>
    </tr>
    <tr>
      <td class="rowprompt">
        Risks
      </td>
      <td class="rowcontent">
        {{ dar?.risks }}
      </td>
    </tr>

    <tr>
      <td class="rowprompt">
        Constraints
      </td>
      <td class="rowcontent">
        {{ dar?.constraints }}
      </td>
    </tr>

    <tr>
      <td class="rowprompt">
        Cause
      </td>
      <td class="rowcontent">
        {{ dar?.cause }}
      </td>
    </tr>

    <!-- darUsers$ -->

    <tr>
      <td colspan="2">
        <div class="rowprompt">Participants</div>
        <mat-card class="scrollableCard">
          <table class="subTable">
            <tr class="tableHeader">
              <th>Email</th>
              <th>Display Name</th>
              <th>Roles</th>
              <ng-container *ngIf="dar.darMethod != DarMethod.Process">
                <th>Vote</th>
              </ng-container>
              <th>Confirmed</th>
            </tr>
            <ng-container *ngFor="let darUser of darUsers$ | async">
              <tr>
                <td>
                  <a
                    href="mailto:{{ darUser.email }}?Subject=DAR: {{
                      dar.title
                    }}"
                    >{{ darUser.email }}</a
                  >
                </td>
                <td>{{ darUser.displayName }}</td>
                <td>
                  <span *ngIf="darUser.isOwner">Owner </span>
                  <span *ngIf="darUser.isStakeholder">Stakeholder </span>
                  <span *ngIf="darUser.isEvaluator">Evaluator </span>
                  <span *ngIf="darUser.isVoter">Voter </span>
                  <span *ngIf="darUser.isReader">Reader </span>
                </td>
                <ng-container *ngIf="dar.darMethod != DarMethod.Process">
                  <td>
                    <ng-container *ngIf="darUser.isVoter">
                      {{
                        darUser?.solutionVote
                          ? darUser?.solutionVote?.name
                          : "-"
                      }}
                    </ng-container>
                  </td>
                </ng-container>
                <td style="text-align: center">
                  <i
                    class="material-icons"
                    *ngIf="darUser.isStakeholder && darUser?.confirmed"
                  >
                    done
                  </i>
                  <div *ngIf="darUser.isStakeholder && !darUser.confirmed">
                    -
                  </div>
                </td>
              </tr>
            </ng-container>
          </table>
        </mat-card>
      </td>
    </tr>

    <!-- user comments -->
    <tr>
      <td colspan="2">
        <div class="rowprompt">Comments</div>
        <mat-card>
          <ng-container *ngFor="let darUser of darUsers$ | async">
            <div *ngIf="darUser.voteComment && darUser.voteComment != ''">
              {{ darUser.displayName }} (Vote): {{ darUser?.voteComment }}
            </div>
            <div *ngIf="darUser.confirmComment && darUser.confirmComment != ''">
              {{ darUser.displayName }} (Confirmation) :
              {{ darUser?.confirmComment }}
            </div>
          </ng-container>
        </mat-card>
      </td>
    </tr>

    <!-- darSolutions$ -->
    <tr>
      <td colspan="2">
        <div class="rowprompt">Solutions</div>
        <mat-card class="scrollableCard">
          <table class="subTable">
            <tr class="tableHeader">
              <th style="min-width: 100px">Name</th>
              <th>Description</th>
              <th>Evaluation Notes</th>
            </tr>
            <ng-container *ngFor="let darSolution of darSolutions$ | async">
              <tr>
                <ng-container *ngIf="sm">
                  <td>{{ darSolution.name }}</td>
                  <td>
                    {{
                      darSolution.description.length < 50
                        ? darSolution.description
                        : darSolution.description.substring(0, 50) + "..."
                    }}
                  </td>
                  <td>
                    {{
                      darSolution.evaluationNotes.length < 50
                        ? darSolution.evaluationNotes
                        : darSolution.evaluationNotes.substring(0, 50) + "..."
                    }}
                  </td>
                </ng-container>
                <ng-container *ngIf="!sm">
                  <td>{{ darSolution.name }}</td>
                  <td>{{ darSolution.description }}</td>
                  <td>{{ darSolution.evaluationNotes }}</td>
                </ng-container>
              </tr>
            </ng-container>
          </table>
        </mat-card>
      </td>
    </tr>

    <!-- darCriteria -->

    <tr>
      <td colspan="2">
        <div class="rowprompt">Criteria</div>
        <mat-card class="scrollableCard">
          <table class="subTable">
            <tr class="tableHeader">
              <th style="min-width: 150px">Name</th>
              <th>Description</th>
              <th style="min-width: 100px">Weighting</th>
            </tr>
            <ng-container *ngFor="let darCriteria of darCriterias$ | async">
              <tr>
                <td>{{ darCriteria.name }}</td>

                <td *ngIf="!sm">{{ darCriteria.description }}</td>
                <td *ngIf="sm">
                  {{
                    darCriteria.description.length < 50
                      ? darCriteria.description
                      : darCriteria.description.substring(0, 50) + "..."
                  }}
                </td>

                <td>{{ CriteriaWeighting[darCriteria.weighting] }}</td>
              </tr>
            </ng-container>
          </table>
        </mat-card>
      </td>
    </tr>

    <!-- evaluation heatmap -->

    <tr>
      <td colspan="2">
        <div class="rowprompt">Solution Evaluation Matrix</div>
        <mat-card class="scrollableCard">
          <app-darviewevaluation [dar]="dar"></app-darviewevaluation>
        </mat-card>
      </td>
    </tr>
  </table>
</div>
