<div class="page-container">
  <form class="standard-form" [formGroup]="form">
    <mat-form-field class="full-width">
      <input matInput formControlName="title" (change)="onFieldUpdate('title')" placeholder="Title"
       required [disableControl]="!allowUpdate"/>
      <mat-error *ngIf="form.controls['title'].hasError('required')">
        Title is required.
      </mat-error>
      <mat-error *ngIf="form.controls['title'].hasError('minlength')">
        Title must be at least 10 characters long.
      </mat-error>
      <mat-error *ngIf="form.controls['title'].hasError('maxlength')">
        Title can not be more than 60 characters long.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
      <textarea matInput formControlName="description" (change)="onFieldUpdate('description')" matAutosizeMinRows="7"
        matAutosizeMaxRows="20" matTextareaAutosize placeholder="Description" required [disableControl]="!allowUpdate"></textarea>
      <mat-error *ngIf="form.controls['description'].hasError('required')">
        Description is required.
      </mat-error>
      <mat-error *ngIf="form.controls['description'].hasError('minlength')">
        Description must be at least 50 characters long.
      </mat-error>
      <mat-error *ngIf="form.controls['description'].hasError('maxlength')">
        Description must be less than 10,000 characters long.
      </mat-error>
    </mat-form-field>

    <ng-container *ngIf="darNextStatus.comment && darNextStatus.comment != ''">
      <div class="full-width page-hint"
        matTooltip="Information about the next available status for this DAR and suggested actions">

        <i class="material-icons-outlined" style="color: #d5aa46">
          emoji_objects
        </i>

        {{ darNextStatus.comment }} <i>{{darNextStatus.explanation}}</i>


      </div>
    </ng-container>

    <mat-form-field class="half-width">
      <mat-label>Status</mat-label>
      <mat-select formControlName="darStatus" (selectionChange)="onFieldUpdate('darStatus')">
        <mat-option *ngFor="let item of nextDarStatuses" [value]="item.key">
          {{ item.value | titlecase }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls['darStatus'].hasError('required')">
        A status must be selected.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="half-width">
      <mat-label>Methodology</mat-label>
      <mat-select formControlName="darMethod" (selectionChange)="onFieldUpdate('darMethod')" [disableControl]="!allowUpdate">
        <mat-option *ngFor="let item of darMethods" [value]="item.key">
          {{ item.value }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls['darMethod'].hasError('required')">
        A methodology must be selected.
      </mat-error>
      <mat-hint>How the solution will be decided.</mat-hint>
    </mat-form-field>



    <mat-form-field class="half-width">
      <input matInput placeholder="Target Date" formControlName="dateTargeted" [matDatepicker]="myDatepicker"
        (dateChange)="onFieldUpdate('dateTargeted','Timestamp')" [disableControl]="!allowUpdate"/>
      <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #myDatepicker></mat-datepicker>
      <mat-hint>When the decision should be complete</mat-hint> 
    </mat-form-field>

    <mat-form-field class="half-width">
      <mat-label>Team</mat-label>
      <mat-select (selectionChange)="onFieldUpdate('tid')" formControlName="tid" [disableControl]="!allowUpdate">
        <mat-option value="NA">N/A</mat-option>
        <mat-option *ngFor="let team of team$ | async" [value]="team.id">
          {{ team.name }}
        </mat-option>
      </mat-select>
      <mat-hint>Team associated with this decision</mat-hint>
    </mat-form-field>





    <mat-form-field class="full-width">
      <mat-label>Chosen Solution</mat-label>
      <mat-select formControlName="dsid" (selectionChange)="onFieldUpdate('dsid','Blankable')">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let darsolution of (darSolutions$ | async)" [value]="darsolution.id">
          {{ darsolution.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width">
      <textarea matInput formControlName="risks" (change)="onFieldUpdate('risks')" matAutosizeMinRows="4"
        matAutosizeMaxRows="20" matTextareaAutosize placeholder="Risks" [disableControl]="!allowUpdate"></textarea>
      <mat-error *ngIf="form.controls['risks'].hasError('maxlength')">
        Risks must be less than 10000 characters long.
      </mat-error>
      <mat-hint>Risks associated with this decision</mat-hint>
    </mat-form-field>

    <mat-form-field class="full-width">
      <textarea matInput formControlName="constraints" (change)="onFieldUpdate('constraints')" matAutosizeMinRows="4"
        matAutosizeMaxRows="20" matTextareaAutosize placeholder="Constraints" [disableControl]="!allowUpdate"></textarea>
      <mat-error *ngIf="form.controls['constraints'].hasError('maxlength')">
        Constraints must be less than 10000 characters long.
      </mat-error>
      <mat-hint>Process, financial, political, architectural, etc</mat-hint>
    </mat-form-field>

    <mat-form-field class="full-width">
      <textarea matInput formControlName="cause" (change)="onFieldUpdate('cause')" matAutosizeMinRows="4"
        matAutosizeMaxRows="20" matTextareaAutosize placeholder="Cause" [disableControl]="!allowUpdate"></textarea>
      <mat-error *ngIf="form.controls['cause'].hasError('maxlength')">
        Cause must be less than 10000 characters long.
      </mat-error>
      <mat-hint>Reason for requiring this decision to be made.</mat-hint>
    </mat-form-field>

    <br />
    <br />
    <button mat-raised-button color="primary" *ngIf="crudAction == Crud.Create" [disabled]="!form.valid"
      (click)="onCreate()" id="btnCreate">
      <mat-icon>add</mat-icon>
      Create
    </button>

    <button mat-raised-button color="primary" *ngIf="crudAction == Crud.Delete" (click)="onDelete()" id="btnDelete">
      <mat-icon>clear</mat-icon>
      Delete
    </button>


    <button mat-raised-button color="primary" *ngIf="crudAction == Crud.Update" (click)="onView()" id="btnView">
      <mat-icon>
        menu_book
      </mat-icon> View DAR
    </button>
  </form>
</div>
