<app-subheading title="Confirm this DAR is Complete"></app-subheading>
<div class="page-container">
  <mat-label>Confirm</mat-label>
  <div class="confirm-indent">
    <mat-checkbox
      (change)="onConfirmatedUpdate()"
      [checked]="(daruser$ | async)?.confirmed"
      #confirmDar
      >Confirm</mat-checkbox
    >
  </div>
  <mat-label>Comment</mat-label>
  <div class="confirm-indent">
    <textarea
      class="confirm-input"
      style="width: 100%;"
      matInput
      (change)="onConfirmCommentUpdate()"
      matAutosizeMaxRows="20"
      matAutosizeMinRows="4"
      matTextareaAutosize
      placeholder="Confirmation Comments"
      #confirmComment
      >{{ (daruser$ | async)?.confirmComment }}</textarea
    >
  </div>
  <br />

  <i
    >Note: Review the DAR information below and as stakeholder confirm that the
    DAR has been performed correctly and you agree that the appropriate solution
    has been chosen. You can enter any comments about why you are confirming, or
    are not confirming this DAR. Once all the stakeholders have confirmed the
    DAR this DAR will be closed.</i
  >
  <br /><br />

  <app-subheading title="Dar Information"></app-subheading>
  <mat-card>
    <app-darview></app-darview>
  </mat-card>
</div>
