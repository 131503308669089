<app-subheading title="Export DAR as JSON"></app-subheading>
<div class="page-container">
  <textarea class="full-width" matInput #txtJSON rows="20">
&#123;"dar" : {{ dar | json }} , "darSolutions" :
{{ darSolutions$ | async | json }} , "darUsers" :
{{ darUsers$ | async | json }} , "darCriteria" :
{{ darCriteria$ | async | json }} , "darEvaluations" :
{{ darEvaluations$ | async | json }}
&#125;
  </textarea>
  <button mat-raised-button color="primary" (click)="onCopy()">
    <mat-icon>file_copy</mat-icon>
    Copy
  </button>
</div>
