<app-subheading [title]="'User: ' +  user?.email"></app-subheading>
<div class="page-container">

  <!-- Use an array of keyValuePairs to display most of the user properties -->
  <table>
    <tr *ngFor="let kvp of kvps">
      <td>
        <b>{{ kvp.key }}</b>
      </td>
      <td>{{ kvp.value }}</td>
    </tr>
    <tr>
      <td>
        <b>Photo</b>
      </td>
      <td><img src="{{ user.photoURL }}" height="48px" /></td>
    </tr>
    <ng-container *ngIf="updatableProfile">
      <tr>
        <td>
          <b>Is&nbsp;Administrator?</b>
        </td>
        <td>
          <mat-checkbox [(ngModel)]="user.isAdmin" (click)="updateIsAdmin()"></mat-checkbox>
        </td>
      </tr>
      <tr>
        <td>
          <b>Is&nbsp;Activated?</b>
        </td>
        <td>
          <mat-checkbox [(ngModel)]="user.isActivated" (click)="updateIsActivated()"></mat-checkbox>
        </td>
      </tr>
      <tr>
        <td>
          <b>Is&nbsp;DarCreator?</b>
        </td>
        <td>
          <mat-checkbox [(ngModel)]="user.isDarCreator" (click)="updateIsDarCreator()"></mat-checkbox>
        </td>
      </tr>
      <tr>
        <td>
          <b>Team</b>
        </td>
        <td>
          <mat-select (selectionChange)="updateTeam($event)" placeholder="Team" [(value)]="user.team.tid">
            <mat-option value="">N/A</mat-option>
            <mat-option *ngFor="let team of teams" [value]="team.id">
              {{ team.name }}
            </mat-option>
          </mat-select>
        </td>
      </tr>
    </ng-container>
  </table>
</div>
