<div class="page-container">
  <div class="table-container">
    <div *ngIf="(darusers$ | async)?.length == 0">
      No users defined. Press "New User" to create new participants for this
      decision.
    </div>
    <table mat-table [dataSource]="darusers$ | async" class="mat-elevation-z8">
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef style="padding-left: 20px">
          User (Click to edit)
        </th>
        <td mat-cell *matCellDef="let element" style="width: 100%">
          <button
            mat-button
            (click)="selectUser(element.id)"
            style="width: 100%;text-align: left;"
          >
            {{ element.email }}
            <span *ngIf="element.displayName">
              ({{ element.displayName }})</span
            >
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="Roles">
        <th mat-header-cell *matHeaderCellDef>Roles</th>
        <td mat-cell *matCellDef="let element">
          <div class="userRole" *ngIf="element.isOwner">Owner</div>
          <div class="userRole" *ngIf="element.isStakeholder">Stakeholder</div>
          <div class="userRole" *ngIf="element.isEvaluator">Evaluator</div>
          <div class="userRole" *ngIf="element.isReader">Reader</div>
          <div class="userRole" *ngIf="element.isVoter">Voter</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="width:20px">
          <ng-container *ngIf="element.id != auth.currentUser.uid">
            <mat-icon
              class="deleteIcon"
              matTooltip="Delete Criteria"
              (click)="selectDeleteUser(element.id)"
              >clear
            </mat-icon>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <button style="margin-top: 5px;" mat-raised-button (click)="onCreateNew()">
    <mat-icon>add</mat-icon>New User
  </button>

  <app-daruser
    [did]="dar.id"
    [duid]="duid"
    [crudAction]="crudAction"
    [dummyValue]="forCD"
  ></app-daruser>
</div>
