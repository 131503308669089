<app-subheading [title]="team.name" *ngIf="crudAction == Crud.Update"></app-subheading>
<app-subheading title="Create New Team" *ngIf="crudAction == Crud.Create"></app-subheading>
<app-subheading [title]="'Delete: ' + team.name" *ngIf="crudAction == Crud.Delete"></app-subheading>
<div class="page-container">

  <form class="standard-form" [formGroup]="teamForm">
    <mat-form-field class="full-width">
      <input
        matInput
        formControlName="name"
        (change)="onFieldUpdate('name')"
        placeholder="Team Name"
        required
      />
      <mat-error *ngIf="teamForm.controls['name'].hasError('required')">
        Team name is required.
      </mat-error>
      <mat-error *ngIf="teamForm.controls['name'].hasError('minlength')">
        Team name must be at least 3 characters long.
      </mat-error>
      <mat-error *ngIf="teamForm.controls['name'].hasError('maxlength')">
        Team name must be no more than 30 characters long.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width">
      <textarea
        matInput
        formControlName="description"
        (change)="onFieldUpdate('description')"
        matAutosizeMinRows="7"
        matAutosizeMaxRows="20"
        matTextareaAutosize
        placeholder="Team Description"
        required
      ></textarea>
      <mat-error *ngIf="teamForm.controls['description'].hasError('required')">
        Description is required.
      </mat-error>
      <mat-error *ngIf="teamForm.controls['description'].hasError('minlength')">
        Description must be at least 20 characters long.
      </mat-error>
      <mat-error *ngIf="teamForm.controls['description'].hasError('maxlength')">
        Description must be no more than 500 characters long.
      </mat-error>
    </mat-form-field>
    <button
      mat-raised-button
      color="primary"
      *ngIf="crudAction == Crud.Create"
      [disabled]="!teamForm.valid"
      (click)="onCreate()"
      id="btnCreate"
    ><mat-icon>add</mat-icon>
      Create
    </button>
    <button
    mat-raised-button
    color="primary"
    *ngIf="crudAction == Crud.Delete"
    (click)="onDelete()"
    id="btnDelete"
  ><mat-icon>clear</mat-icon>
    Delete
  </button>
  </form>
</div>
